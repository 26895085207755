@tailwind base;
@tailwind components;
@tailwind utilities;

.borderBottom {
  /* border-bottom: 0.1px solid gray; */
  box-shadow: 10px 10px 10px rgba(202, 150, 6, 0.3);
}

.btn-tailgate {
  @apply px-8 py-2.5 font-semibold tracking-wider uppercase bg-yellow-500 border-2 border-yellow-500 rounded-md text-yellow-50 hover:bg-yellow-600 hover:border-transparent transition duration-200 shadow-md  focus:outline-none;
}
.btn-tailgate-alt {
  @apply px-8 py-2.5 font-semibold tracking-wider uppercase bg-transparent border-2 border-yellow-500 rounded-sm text-yellow-500 hover:text-yellow-50 hover:bg-yellow-500 hover:border-transparent transition duration-200 shadow-md  focus:outline-none;
}

.bg-pricing-gradient {
  @apply bg-gradient-to-r from-red-400 to-yellow-500;
}

.bg-pricing-gradient-alt {
  @apply bg-gradient-to-r from-yellow-500 to-red-400;
}
